(function () {
    const intro = document.getElementById('intro');
    intro.style.height = `${2.5*window.innerHeight}px`;

    const content = document.getElementById('intro-content');
    content.style.position = 'fixed';
    content.style.bottom = '0';

    const svg = document.getElementById('intro-logo');
    svg.style.position = 'fixed';
    svg.style.top = `${(window.innerHeight/2) - (svg.clientHeight/2)}px`;
    svg.style.left = `${(window.innerWidth/2) - (svg.clientWidth/2)}px`;
    svg.style.transform = 'none';

    let length = svg.children.length;
    const g = svg.querySelector('g');

    const innerWidth = window.innerWidth;
    const innerHeight = window.innerHeight;

    while (length--) {
        let item = svg.children[length];

        if (g !== item) {
            let translateX = (((Math.random() * 2) - 1) * innerWidth) | 0;
            let translateY = (((Math.random() * 2) - 1) * innerHeight) | 0;
            let rotate = Math.random() * 180;
            item.style.transform = `translate(${translateX}px, ${translateY}px) rotate(${rotate}deg)`;
        } else {
            item.style.opacity = 0;
        }
    }

    svg.style.opacity = 0.1;

    window.addEventListener('scroll', () => {
        if (window.scrollY > (0.2*window.innerHeight) && content.style.opacity !== '0') {
            window.requestAnimationFrame(animateForward);
        }
        if (window.scrollY <= (0.2*window.innerHeight) && content.style.opacity === '0') {
            window.requestAnimationFrame(animateBackword);
        }

        if (window.scrollY >= (window.innerHeight*1.25) && (window.scrollY < 2.5*window.innerHeight)) {
            let height = window.innerHeight*1.25;
            svg.style.transform = `translateY(${(height-window.scrollY)*0.35}px) scale(${height/window.scrollY})`;
        }
    });

    function animateForward() {
        content.style.transition = 'opacity 2s ease-in-out';
        content.style.opacity = '0';

        let length = svg.children.length;

        while (length--) {
            let item = svg.children[length];

            if (g !== item) {
                item.style.transition = `transform 4s ease-in-out`;
                item.style.transform = `none`;
            } else {
                item.style.transition = `opacity 1.5s 1.5s ease-in-out`;
                item.style.opacity = 1;
            }
        }

        svg.style.transition = `opacity 4s ease-in-out`;
        svg.style.opacity = 1;

    }

    function animateBackword() {
        content.style.transition = 'opacity 2s ease-in-out';
        content.style.opacity = '1';

        let length = svg.children.length;

        while (length--) {
            let item = svg.children[length];
    
            if (g !== item) {
                let translateX = (((Math.random() * 2) - 1) * innerWidth) | 0;
                let translateY = (((Math.random() * 2) - 1) * innerHeight) | 0;
                let rotate = Math.random() * 180;
                item.style.transform = `translate(${translateX}px, ${translateY}px) rotate(${rotate}deg)`;
            } else {
                item.style.opacity = 0;
            }
        }

        svg.style.opacity = 0.1;
    }

    let observer = new IntersectionObserver((entries, observer) => {
        entries.forEach((entry) => {
            if (entry.isIntersecting) {
                entry.target.classList.add('animation-active');
            } else {
                entry.target.classList.remove('animation-active');
            }
        });
    }, {threshold: 0.25});

    Array.from(document.querySelectorAll('.animation')).forEach((element) => observer.observe(element));
})();
